import { CameraAnimation } from "@/components/r3f/animations/camera-animation";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { useCenterCameraOnPointCloud } from "@/hooks/use-center-camera-on-pointcloud";
import { ModeTransitionProps } from "@/modes/mode";
import { useCurrentScene } from "@/modes/mode-data-context";
import { useCached3DObject } from "@/object-cache";
import { assert } from "@faro-lotv/foundation";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useState } from "react";
import { Matrix4, Quaternion, Vector3 } from "three";

/** @returns The transition to the clipping box mode */
export function ClippingBoxModeTransition({
  onCompleted,
}: ModeTransitionProps): JSX.Element {
  const { main } = useCurrentScene();
  assert(
    main && isIElementPointCloudStream(main),
    "Clipping Box Mode requires a PointCloud as the main element",
  );

  const camera = useThree((s) => s.camera);

  const pointCloudObject = useCached3DObject(main);

  const { target: realTarget, targetCameraPos } = useCenterCameraOnPointCloud(
    camera,
    pointCloudObject,
  );

  // Compute the target camera state
  const [target] = useState(() => {
    // Get the rotation that the camera needs to have to look at the center of the dollhouse
    const mat = new Matrix4().lookAt(targetCameraPos, realTarget, camera.up);
    const quaternion = new Quaternion();
    // Get the quaternion from the matrix
    mat.decompose(new Vector3(), quaternion, new Vector3());

    // Return the target position and rotation of the camera
    return {
      position: targetCameraPos,
      quaternion,
    };
  });

  return (
    <>
      <CameraAnimation {...target} onAnimationFinished={onCompleted} />
      <PointCloudRenderer pointCloud={pointCloudObject} />
    </>
  );
}
