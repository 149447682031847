import { useCached3DObjectIfReady } from "@/object-cache";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { changeMode } from "@/store/mode-slice";
import {
  selectIsControlPointLayerAlignment,
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import {
  setReferenceCloudForAlignment,
  setSheetIdForAlignment,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  ExclamationMarkCircleFillIcon,
  FaroButton,
  FaroStep,
  FaroText,
} from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementAreaSection,
  isIElementGenericImgSheet,
  isIElementGenericPointCloudStream,
  isIElementModel3dStream,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/system";
import { useMemo } from "react";
import { AlignmentStepper } from "../alignment-modes-commons/alignment-stepper";

interface AlignWizardProgressBarProps {
  /** A callback that is called when the user clicks "Next" button in the progressbar */
  goToNextStep(): void;
}

/**
 * @returns The progress bar that is shown at the top of an alignment Wizard mode
 *  Allows user to see where there are currently in the alignment process
 */
export function AlignWizardProgressBar({
  goToNextStep,
}: AlignWizardProgressBarProps): JSX.Element {
  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const referenceElementId = useAppSelector(selectWizardReferenceElementId);
  const isControlPointLayerAlignment = useAppSelector(
    selectIsControlPointLayerAlignment,
  );

  const referenceElement = useAppSelector(selectIElement(referenceElementId));
  const elementToAlign = useAppSelector(selectIElement(elementToAlignId));

  const showNonScaledSheetBanner =
    elementToAlign &&
    referenceElement &&
    isIElementSectionDataSession(elementToAlign) &&
    isIElementAreaSection(referenceElement) &&
    (!referenceElement.pose?.scale || referenceElement.pose.scale.x === 1);

  const activeCad = useAppSelector(selectActiveCadModel);
  const cadModelObject = useCached3DObjectIfReady(activeCad);

  let isReferenceElementReady = isControlPointLayerAlignment;
  if (referenceElement && !isControlPointLayerAlignment) {
    // in case if reference element could be a cloud check if it present in cache before allowing go to actual alignment
    isReferenceElementReady = isIElementModel3dStream(referenceElement)
      ? !!cadModelObject
      : true;
  }

  const steps: FaroStep[] = useMemo(
    () => [
      {
        key: "1",
        label: "Select reference",
        allowNext: () => !!elementToAlignId && isReferenceElementReady,
      },
    ],
    [elementToAlignId, isReferenceElementReady],
  );

  return (
    <>
      {!showNonScaledSheetBanner && (
        <AlignmentStepper
          steps={steps}
          lastStepButtonText="Align"
          hideStepNumbers
          onLastStepButtonClicked={goToNextStep}
        />
      )}
      {showNonScaledSheetBanner && <NonScaledSheetActionBanner />}
    </>
  );
}

/**
 * @returns notification bar with two buttons proposing options to align cloud to not-scaled sheet
 */
function NonScaledSheetActionBanner(): JSX.Element {
  const dispatch = useAppDispatch();

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const elementToAlign = useAppSelector(selectIElement(elementToAlignId));

  const referenceElementId = useAppSelector(selectWizardReferenceElementId);
  const referenceElement = useAppSelector(selectIElement(referenceElementId));

  assert(
    elementToAlign &&
      referenceElement &&
      isIElementSectionDataSession(elementToAlign) &&
      isIElementAreaSection(referenceElement),
    "invalid elements selection for NonScaledSheetActionBanner control",
  );

  const activeSheet = useAppSelector(
    selectChildDepthFirst(referenceElement, isIElementGenericImgSheet),
  );

  const cloudStream = useAppSelector(
    selectChildDepthFirst(elementToAlign, isIElementGenericPointCloudStream),
  );

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      role="banner"
      sx={{
        // height adjusted to be same as height of AlignWizardProgressBar
        height: 74,
        p: 1,
        border: ({ palette }) => `1px solid ${palette.black10}`,
        background: ({ palette }) => palette.warning.light,
      }}
    >
      <ExclamationMarkCircleFillIcon sx={{ color: "warning.main", ml: 1 }} />
      <FaroText variant="heading16">Floor plan not scaled</FaroText>
      <FaroText
        variant="bodyM"
        sx={{
          flexGrow: 1,
        }}
      >
        The selected floor plan isn't scaled and can't be used as a reference.
        Please scale the floor plan or align it to the point cloud.
      </FaroText>

      <FaroButton
        role="action-button"
        variant="ghost"
        onClick={() => dispatch(changeMode("floorscale"))}
      >
        Scale floor plan
      </FaroButton>

      <FaroButton
        role="action-button"
        variant="primary"
        onClick={() => {
          if (activeSheet && cloudStream) {
            dispatch(setSheetIdForAlignment(activeSheet.id));
            dispatch(setReferenceCloudForAlignment(cloudStream.id));
            dispatch(changeMode("sheetToCloudAlignment"));
          }
        }}
      >
        Use point cloud as reference
      </FaroButton>
    </Stack>
  );
}
