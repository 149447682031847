import { areConnectionsEqual } from "@/data-preparation-tool/utils/edge-utils";
import { curryAppSelector } from "@/store/reselect";
import { RootState } from "@/store/store";
import { selectCurrentUser } from "@/store/user-selectors";
import { EMPTY_ARRAY, GUID } from "@faro-lotv/foundation";
import {
  IElementGenericPointCloudStream,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  RegistrationEdgeRevision,
  RegistrationEdgeType,
  RevisionScanEntity,
  RevisionStatus,
  isRevisionScanEntity,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";
import {
  getAncestorIds,
  getDescendantIds,
  selectCandidateEdges,
  selectEntityTransformOverrides,
  selectIsEntityVisibleRecursive,
  selectPointCloudStreamForScanEntity,
  selectRegistrationEdgeType,
  selectRevisionEntity,
  selectRevisionEntityAllDescendants,
  selectRevisionEntityChildrenMap,
  selectRevisionEntityMap,
  selectRevisionRegistrationEdges,
} from "../revision-selectors";
import {
  RegistrationEditMode,
  UserEdit,
  UserEditAddConnection,
} from "./data-preparation-ui-slice";

/**
 * @returns the selected entities' id in the scan tree
 * @param state current app state
 */
export function selectSelectedEntityIds(state: RootState): GUID[] {
  return state.dataPreparationUi.selectedEntityIds;
}

/**
 * @returns all point cloud streams for and beneath the given entity
 * @param id the entity to get the point cloud streams for
 */
export function selectPointCloudStreamsForEntity(id?: GUID) {
  return (state: RootState): IElementGenericPointCloudStream[] => {
    if (!id) {
      return EMPTY_ARRAY;
    }

    const entity = selectRevisionEntity(id)(state);

    if (!entity) {
      return EMPTY_ARRAY;
    }

    const children = selectRevisionEntityAllDescendants(entity.id)(state);

    return [entity, ...children]
      .filter(isRevisionScanEntity)
      .map((e) => selectPointCloudStreamForScanEntity(e)(state))
      .filter(isValid);
  };
}

/**
 * @returns all point cloud stream ids for and beneath the given entity
 * @param id the entity to get the point cloud stream ids for
 */
function selectPointCloudStreamIdsForEntity(id?: GUID) {
  return (state: RootState): GUID[] =>
    selectPointCloudStreamsForEntity(id)(state).map((pcs) => pcs.id);
}

/**
 * @returns all point cloud streams for the selected entities.
 * @param state current app state
 */
export const selectPointCloudStreamIdsForSelectedEntity = createSelector(
  [(state: RootState) => state],
  (state: RootState): GUID[] | undefined => {
    if (!state.dataPreparationUi.selectedEntityIds.length) {
      return;
    }

    return state.dataPreparationUi.selectedEntityIds.flatMap((entityId) =>
      selectPointCloudStreamIdsForEntity(entityId)(state),
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } },
);

/**
 * @param state current application state
 * @returns the entities that user edits should be applied to.
 *  In particular, this excludes clusters if some of their children are selected individually.
 */
export const selectEntitiesToEdit = createSelector(
  [selectSelectedEntityIds, selectRevisionEntityChildrenMap],
  (selectedEntityIds, childrenMap) =>
    // If individual entities within a cluster are selected, only edit those entities instead of the whole cluster
    selectedEntityIds.filter(
      (id) =>
        !getDescendantIds(childrenMap, id).some(
          (descendantId) =>
            descendantId !== id && selectedEntityIds.includes(descendantId),
        ),
    ),
);

/**
 * @returns all scans that are selected directly or indirectly (through selected clusters).
 *  This will be exactly the scans which are also manipulated when editing.
 */
export const selectSelectedScansRecursive = createSelector(
  [
    selectEntitiesToEdit,
    selectRevisionEntityChildrenMap,
    selectRevisionEntityMap,
  ],
  (entitiesToEdit, childrenMap, entityMap): RevisionScanEntity[] =>
    entitiesToEdit
      .flatMap((id) => getDescendantIds(childrenMap, id))
      .map((id) => entityMap[id])
      .filter((entity) => !!entity && isRevisionScanEntity(entity)),
);

/**
 * @description is a memoized selector that derives the ids of scans returned by selectSelectedScansRecursive selector
 * @returns all scan ids that are selected directly or indirectly (through selected clusters).
 */
export const selectSelectedScanIdsRecursive = createSelector(
  [selectSelectedScansRecursive],
  (selectedScans): GUID[] => selectedScans.map((scan) => scan.id),
);

/**
 * @param state current app state
 * @returns The ID of the entity that is currently being hovered or `undefined` otherwise.
 */
export function selectHoveredEntityId(state: RootState): GUID | undefined {
  return state.dataPreparationUi.hoveredEntityId;
}

/**
 * @returns true if the given entity is being hovered
 * @param state current app state
 * @param entityId the entity to check
 */
export const selectIsEntityDirectlyHovered = curryAppSelector(
  createSelector(
    [selectHoveredEntityId, (state, entityId?: GUID) => entityId],
    (hoveredEntityId, entityId): boolean =>
      !!entityId && hoveredEntityId === entityId,
  ),
);

/**
 * @returns all scan ids that are indirectly hovered (e.g when we hover on a cluster).
 */
export const selectHoveredEntityIdsRecursive = createSelector(
  [selectHoveredEntityId, selectRevisionEntityChildrenMap],
  (entityId, childrenMap): GUID[] | undefined => {
    if (entityId !== undefined) {
      return getDescendantIds(childrenMap, entityId);
    }
  },
);

/**
 * @returns true if the given entity is being indirectly hovered (e.g. entity is a part of hovering cluster)
 * @param state current app state
 * @param entityId the entity to check
 */
export const selectIsEntityIndirectlyHovered = curryAppSelector(
  createSelector(
    [selectHoveredEntityIdsRecursive, (state, entityId?: GUID) => entityId],
    (hoveredEntityIds, entityId): boolean => {
      if (!!entityId && !!hoveredEntityIds) {
        return hoveredEntityIds.includes(entityId);
      }
      return false;
    },
  ),
);

/**
 * @returns all point cloud streams for the hovered entity
 * @param state current app state
 */
export const selectPointCloudStreamIdsForHoveredEntity = createSelector(
  [(state: RootState) => state],
  (state: RootState): GUID[] | undefined => {
    if (!state.dataPreparationUi.hoveredEntityId) {
      return;
    }

    return selectPointCloudStreamIdsForEntity(
      state.dataPreparationUi.hoveredEntityId,
    )(state);
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } },
);

/**
 * @param state The current application state
 * @returns Whether the user can edit the registration results.
 */
export function selectIsAnyEditModeEnabled(state: RootState): boolean {
  return state.dataPreparationUi.editMode !== undefined;
}

/**
 * @param state The current application state
 * @returns The edit mode which is currently enabled, or `undefined` if editing is disabled.
 */
export function selectEditMode(
  state: RootState,
): RegistrationEditMode | undefined {
  return state.dataPreparationUi.editMode;
}

/**
 * @param state The current application state
 * @returns Whether the user can edit scans, i.e. move and rotate them.
 */
export function selectIsEditingScans(state: RootState): boolean {
  return state.dataPreparationUi.editMode === RegistrationEditMode.editScans;
}

/**
 * @param state The current application state
 * @returns Whether the user can add new connections to the registration.
 */
export function selectIsAddingConnections(state: RootState): boolean {
  return (
    state.dataPreparationUi.editMode === RegistrationEditMode.addConnection
  );
}

/**
 * @param state The current application state
 * @returns Whether the user can delete existing registration edges.
 */
export function selectIsDeletingConnections(state: RootState): boolean {
  return (
    state.dataPreparationUi.editMode === RegistrationEditMode.deleteConnection
  );
}

/**
 * @param state The current application state
 * @returns All edits performed by the user
 */
function selectUserEdits(state: RootState): UserEdit[] {
  return state.dataPreparationUi.userEdits;
}

/**
 * @param state The current application state
 * @returns All edits performed by the user in reversed order.
 *  Useful to show the most recent edits first.
 */
const selectUserEditsReversed = createSelector([selectUserEdits], (userEdits) =>
  [...userEdits].reverse(),
);

/**
 * @param state The current application state
 * @returns The most recent edits for each connection, in the order they were performed.
 *  For each connection, only the most recent edit is included.
 */
const selectMostRecentConnectionEdits = createSelector(
  [selectUserEditsReversed],
  (userEditsReversed) => {
    const connectionEdits: UserEdit[] = [];

    for (const edit of userEditsReversed) {
      // Only take the most recent edit for each connection
      if (!connectionEdits.some((c) => areConnectionsEqual(c, edit))) {
        connectionEdits.push(edit);
      }
    }

    return connectionEdits.reverse();
  },
);

/**
 * @param state The current application state
 * @returns All connections that the user added manually, in the order they were added.
 */
export const selectConnectionsAddedByUser = createSelector(
  [selectMostRecentConnectionEdits],
  (connectionEdits) =>
    connectionEdits.filter((edit) => edit.type === "addConnection"),
);

/**
 * @param state The current application state
 * @returns All connections that the user deleted manually, in the order they were deleted.
 */
export const selectConnectionsDeletedByUser = createSelector(
  [selectMostRecentConnectionEdits],
  (connectionEdits) =>
    connectionEdits.filter((edit) => edit.type === "deleteConnection"),
);

/**
 * @param state The current application state.
 * @returns Candidate edges which were newly added by the user.
 */
export const selectNewCandidateEdges = createSelector(
  [selectConnectionsAddedByUser, selectCandidateEdges, selectCurrentUser],
  (
    addedConnections,
    oldCandidates,
    currentUser,
  ): Array<
    RegistrationEdgeRevision & { type: RegistrationEdgeType.candidate }
  > => {
    if (!currentUser) {
      return EMPTY_ARRAY;
    }

    /**
     * @param added The connection to check for reuse
     * @returns The ID to use for the added edge.
     *  To avoid API errors, the ID of an old edge has to be reused if it exists.
     */
    function reuseOldIdIfPossible(added: UserEditAddConnection): GUID {
      const oldEdge = oldCandidates.find((old) =>
        areConnectionsEqual(old, added),
      );
      if (oldEdge) return oldEdge.id;
      return added.id;
    }

    return addedConnections.map((added) => ({
      ...added,
      id: reuseOldIdIfPossible(added),
      type: RegistrationEdgeType.candidate,
      status: RevisionStatus.added,
      createdBy: currentUser.id,
      lastPatchedAt: added.createdAt,
      lastPatchedBy: currentUser.id,
      data: undefined,
    }));
  },
);

/**
 * @param state The current application state.
 * @returns All available candidate edges, both the ones on the API and the ones added by the user.
 */
const selectAllCandidateEdges = createSelector(
  [selectCandidateEdges, selectNewCandidateEdges],
  (
    oldEdges,
    newEdges,
  ): Array<
    RegistrationEdgeRevision & { type: RegistrationEdgeType.candidate }
  > => {
    const oldEdgesNotOverridden = oldEdges.filter(
      (oldEdge) =>
        !newEdges.some((newEdge) => areConnectionsEqual(newEdge, oldEdge)),
    );
    return [...oldEdgesNotOverridden, ...newEdges];
  },
);

/**
 * @param state The current application state.
 * @param edge The registration edge to check for deletion.
 * @returns Whether the user has deleted the given edge.
 */
const selectIsEdgeDeletedByUser = curryAppSelector(
  createSelector(
    [
      selectConnectionsDeletedByUser,
      (state: RootState, edge: RegistrationEdgeRevision) => edge,
    ],
    (deletedConnections, edge) =>
      deletedConnections.some((deleted) => areConnectionsEqual(edge, deleted)),
  ),
);

/**
 * @param state The current application state.
 * @param edge The registration edge to check for validity.
 * @returns Whether the registration edge is valid, i.e. no related scans have been modified since the registration
 *  and the edge is not deleted by the user.
 */
export const selectIsRegistrationEdgeValid = curryAppSelector(
  createSelector(
    [
      selectRevisionEntityMap,
      selectEntityTransformOverrides,
      (state, edge: RegistrationEdgeRevision) =>
        selectIsEdgeDeletedByUser(edge)(state),
      (state: RootState, edge: RegistrationEdgeRevision) => edge,
    ],
    (entityMap, transformOverrides, isDeleted, edge) => {
      if (isDeleted) return false;
      // Candidate edges are not invalidated by moving scans, as they are manually added by the user
      if (edge.type === RegistrationEdgeType.candidate) return true;

      const sourceAncestorIds = getAncestorIds(entityMap, edge.sourceId);
      const targetAncestorIds = getAncestorIds(entityMap, edge.targetId);

      const sourceScanMoved = sourceAncestorIds.some(
        (id) => !targetAncestorIds.includes(id) && transformOverrides[id],
      );
      const targetScanMoved = targetAncestorIds.some(
        (id) => !sourceAncestorIds.includes(id) && transformOverrides[id],
      );

      return !sourceScanMoved && !targetScanMoved;
    },
  ),
);

/**
 * @returns All loaded registration edges in the revision.
 */
export const selectActiveRegistrationEdges = createSelector(
  [
    selectRevisionRegistrationEdges,
    selectConnectionsDeletedByUser,
    selectAllCandidateEdges,
    selectRegistrationEdgeType,
  ],
  (
    edges,
    connectionsDeletedByUser,
    candidateEdges,
    edgeType,
  ): RegistrationEdgeRevision[] => {
    // The currently selected edge type
    const activeEdges = edges.filter((edge) => edge.type === edgeType);
    // Candidate edges are always included
    activeEdges.push(...candidateEdges);

    // Filter out deleted edges
    return activeEdges.filter(
      (edge: RegistrationEdgeRevision) =>
        !connectionsDeletedByUser.some((deleted) =>
          areConnectionsEqual(edge, deleted),
        ),
    );
  },
);

/**
 * Candidate edges should only be displayed if no other edge connecting the same scans exists.
 *
 * @param edge The candidate edge to check for duplication.
 * @returns `true` if another valid connection exists between the two scans, `false` otherwise.
 */
function selectIsCandidateEdgeDuplicated(edge: RegistrationEdgeRevision) {
  return (state: RootState) =>
    edge.type === RegistrationEdgeType.candidate &&
    selectActiveRegistrationEdges(state).some(
      (activeEdge) =>
        activeEdge.id !== edge.id &&
        selectIsRegistrationEdgeValid(activeEdge)(state) &&
        areConnectionsEqual(activeEdge, edge),
    );
}

/**
 * @param edge The registration edge to check for visibility.
 * @returns Whether the edge should be visible to the user.
 *  Will be hidden if any related scan is hidden or the edge is invalid.
 */
export function selectIsRegistrationEdgeVisible(
  edge: RegistrationEdgeRevision,
) {
  return (state: RootState) =>
    selectIsRegistrationEdgeValid(edge)(state) &&
    selectIsEntityVisibleRecursive(edge.sourceId)(state) &&
    selectIsEntityVisibleRecursive(edge.targetId)(state) &&
    !selectIsCandidateEdgeDuplicated(edge)(state);
}
